// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Banner from './components/Banner';
import MainComponent from './components/Work';
import Project from './components/Project';
import Testimonial from './components/Testimonial';
import Team from './components/Team';
import Contact from './components/Contact';
import Footer from './components/Footer';
import About from './components/About';
import './App.css';

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={
            <>
              <Banner />
              <MainComponent />
              <Project />
              <Testimonial />
              <Team />
              <Contact />
            </>
          } />
          <Route path="/about" element={
            <>
            <About/>
            </>
          } />
          <Route path="/services" element={
            <>
            <MainComponent/>
            </>
          } />
          <Route path="/portfolio" element={
            <>
            <Project/>
            <Testimonial/>
            </>
          } />
          <Route path="/contact" element={
            <>
            <Contact/>
            </>
          } />
           
         
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;