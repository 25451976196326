import React from 'react';
import './Testimonials.css'; // Import your CSS file for styling

const testimonials = [
  {
    name: "Ravi Kumar",
    text: "WebCraft made my e-commerce site in no time! They nailed it. Great service, totally recommend!",
    location: "Delhi, India",
    image: "path/to/ravi.jpg" // Path to Ravi's image
  },
  {
    name: "Priya Singh",
    text: "I wanted a website that stands out, and they delivered! Quick turnaround and super friendly team!",
    location: "Mumbai, India",
    image: "path/to/priya.jpg" // Path to Priya's image
  },
  {
    name: "Amit Sharma",
    text: "WebCraft is my go-to for anything web-related. They understand my needs and get the job done right!",
    location: "Bangalore, India",
    image: "path/to/amit.jpg" // Path to Amit's image
  },
  {
    name: "Sonia Patel",
    text: "Had a blast working with them! They took my ideas and turned them into reality. Totally worth it!",
    location: "Ahmedabad, India",
    image: "path/to/sonia.jpg" // Path to Sonia's image
  },
  {
    name: "Nikhil Mehta",
    text: "These guys are awesome! They made my site in a jiffy, and the quality is top-notch. I'm impressed!",
    location: "Pune, India",
    image: "path/to/nikhil.jpg" // Path to Nikhil's image
  },
  {
    name: "Anjali Verma",
    text: "If you want a hassle-free experience, go with WebCraft! They’re super helpful and the end product is fantastic!",
    location: "Chennai, India",
    image: "path/to/anjali.jpg" // Path to Anjali's image
  },
];

const Testimonial = () => {
  return (
    <div className="testimonial-section">
      <h2>What Our Clients Say</h2>
      <div className="testimonial-container">
        {testimonials.map((testimonial, index) => (
          <div key={index} className="testimonial-card">
            <p className="testimonial-text">"{testimonial.text}"</p>
            <h3 className="testimonial-name">{testimonial.name}</h3>
            <p className="testimonial-location">{testimonial.location}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Testimonial;