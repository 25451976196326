// src/components/Contact.js
import React from 'react';
import './Contact.css';

const Contact = () => {
  return (
    <div className="contact-container">
      <h2 className="contact-title">Get in Touch</h2>
      <div className="contact-info">
        <div className="info-item">
          <h3>Email Us</h3>
          <p>Dhandaeasy@gmail.com</p>
        </div>
        <div className="info-item">
          <h3>Call Us</h3>
          <p>+91 6290529857</p>
        </div>
      </div>
      <form className="contact-form">
        <h3>Contact Form</h3>
        <input type="text" placeholder="Your Name" required />
        <input type="email" placeholder="Your Email" required />
        <textarea placeholder="Your Message" required></textarea>
        <button type="submit" className="submit-button">Send Message</button>
      </form>
    </div>
  );
};

export default Contact;