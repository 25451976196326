// src/components/Project.js
import React from 'react';
import './Project.css';

const projects = [
    {
        id: 1,
        image: "/Screenshot 2024-10-28 165701.png",
        title: "Baquaiveda",
        description: "An E-Commerce web app designed to give service and sell ayurvedic products",
        link: "https://baquiveda-frontend.vercel.app/"
    },
    {
        id: 2,
        image: "/Screenshot 2024-10-28 170235.png",
        title: "Henrytrain",
        description: "An Ed-Tech site for courses and study materials for students",
        link: "https://henrytrain.com/"
    },
    {
        id: 3,
        image: "/Screenshot 2024-10-28 170649.png",
        title: "NextLevelTraders",
        description: "A Trading platform where customers are helped with trading.",
        link: "https://project8-xi.vercel.app/"
    },
    {
        id: 4,
        image: "/Screenshot 2024-10-28 170805.png",
        title: "CutsPay",
        description: "An offline transport token system",
        link: "https://cutspay.in/"
    },
    {
        id: 5,
        image: "/Screenshot 2024-10-28 174151.png",
        title: "PetSree",
        description: "A Pet Sitter App",
        link: "https://petsree.vercel.app/"
    },
    {
        id: 6,
        image: "/Screenshot 2024-10-28 170805.png",
        title: "CutsPay",
        description: "An offline transport token system",
        link: "https://cutspay.in/"
    },
    {
        id: 7,
        image: "/Screenshot 2024-10-28 193814.png",
        title: "Ui Project 1",
        description: "This is a UI/UX Design project.",
        link: "https://www.figma.com/proto/rGNjOcHCvvziYhGw96ONbH/Tasky---Task-and-Time-Management-Dashboard-(Community)?page-id=0%3A1&node-id=511-5789&node-type=frame&viewport=-9066%2C514%2C0.25&t=OEdT56b6UEwiUmKE-1&scaling=scale-down-width&content-scaling=fixed&starting-point-node-id=511%3A5789"
    },
    {
        id: 8,
        image: "/Screenshot 2024-10-28 194343.png",
        title: "Ui Project 2",
        description: "Agency Website Design project.",
        link: "https://www.figma.com/proto/Bmt70LBcMFDjO6hCNYdBtp/Agency-Companies-Free-(Community)?page-id=407%3A2&node-id=616-2683&node-type=frame&viewport=509%2C83%2C0.17&t=KsAgMULQm4CxMM6T-1&scaling=scale-down-width&content-scaling=fixed&starting-point-node-id=616%3A1230"
    },
];

const Project = () => {
    return (
        <div className="container">
            {/* Our Projects Section */}
            <div className="projects-section">
                <h2 className="projects-title">Our Projects</h2>
                <div className="projects-grid">
                    {projects.map((project) => (
                        <div className="project-card" key={project.id}>
                            <img src={project.image} alt={`Screenshot of ${project.title}`} className="project-image" />
                            <h3 className="project-title">{project.title}</h3>
                            <p className="project-description">{project.description}</p>
                            <a href={project.link} className="project-link">View Project</a>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Project;