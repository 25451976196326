// src/components/Header.js
import React from 'react';
import './Header.css';

function Header() {
  return (
    <header className="navbar">
      <div className="logo">
        <img src="/logo.png" alt="Company Logo" /> {/* Added alt attribute */}
      </div>
      <nav className="nav-links">
        <a href="/">Home</a>
        <a href="/about">About</a>
        <a href="/services">Services</a>
        <a href="/portfolio">Portfolio</a>
        <a href="/contact">Contact Us</a>
      </nav>
      <button className="get-started">Get Started</button>
    </header>
  );
}

export default Header;