import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './Banner.css';

function Banner() {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Adjust the animation duration
      easing: 'ease-in-out',
      once: true,
    });
  }, []);

  return (
    <section className="hero-section" data-aos="fade-up">
      <div className="tagline-carousel" data-aos="fade-right">
        <p>DhandaEasy: Streamlining Your Path to Stunning Websites!</p>
      </div>
      <h1 data-aos="fade-up" data-aos-delay="200">
        Unlock your Growth with <span data-aos="zoom-in" data-aos-delay="400">DhandaEasy</span>
      </h1>
      <p className="hero-subtext" data-aos="fade-in" data-aos-delay="600">
        help you to grow with us with modern technologies
      </p>
      <button className="work-with-us" data-aos="fade-up" data-aos-delay="800">Work With Us</button>
      <div className="social-icons" data-aos="fade-up" data-aos-delay="1000">
        <a href="/"><i className="fa fa-instagram"></i></a>
        <a href="/"><i className="fa fa-whatsapp"></i></a>
        <a href="/"><i className="fa fa-linkedin"></i></a>
        <a href="/"><i className="fa fa-twitter"></i></a>
      </div>
    </section>
  );
}

export default Banner;