// src/components/MainComponent.js
import React from 'react';
import './Work.css';

const MainComponent = () => {
  return (
    <div className="container">
      <h1 className="title">Let’s Work Together!</h1>
      <p className="subtitle">
        We help you build modern, user-friendly websites with excellent SEO and clean design.
      </p>

      <div className="section">
        <div className="service-container">
          <div className="card web-dev">
            <div className="icon">🌐</div> {/* Placeholder icon */}
            <h3>Website Development</h3>
            <p>
              Our team specializes in creating responsive and dynamic websites tailored to your business needs. 
              We ensure that every website is optimized for performance and SEO to enhance visibility online.
            </p>
            <a href="/" className="learn-more">Start Your Project</a>
          </div>

          <div className="card ui-ux">
            <div className="icon">🎨</div> {/* Placeholder icon */}
            <h3>UI/UX Design</h3>
            <p>
              We design intuitive and engaging user interfaces that provide a seamless experience for your visitors. 
              Our design philosophy focuses on user-centric design to improve usability and satisfaction.
            </p>
            <a href="/" className="learn-more">Get Started</a>
          </div>

          <div className="card digital-marketing">
            <div className="icon">📈</div> {/* Placeholder icon */}
            <h3>Digital Marketing</h3>
            <p>
              Boost your online presence with our comprehensive digital marketing strategies. 
              From SEO to social media marketing, we tailor our services to ensure maximum reach and engagement.
            </p>
            <a href="/" className="learn-more">Grow Your Audience</a>
          </div>

          <div className="card app-dev">
            <div className="icon">📱</div> {/* Placeholder icon */}
            <h3>Mobile App Development</h3>
            <p>
              Our mobile app development services bring your ideas to life on iOS and Android platforms. 
              We focus on delivering high-performance apps that provide a great user experience.
            </p>
            <a href="/" className="learn-more">Develop Your App</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainComponent;