// src/components/Footer.js
import React from 'react';
import './Footer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter, faLinkedin, faInstagram } from '@fortawesome/free-brands-svg-icons';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-content">
                <div className="footer-links">
                    <h3>Quick Links</h3>
                    <ul>
                        <li><a href="/">Home</a></li>
                        <li><a href="/about">About Us</a></li>
                        <li><a href="/services">Services</a></li>
                        <li><a href="/projects">Projects</a></li>
                        <li><a href="/contact">Contact</a></li>
                    </ul>
                </div>
                <div className="footer-social">
                    <h3>Follow Us</h3>
                    <div className="social-icons">
                        <a href="/" aria-label="Facebook"><FontAwesomeIcon icon={faFacebook} /></a>
                        <a href="/" aria-label="Twitter"><FontAwesomeIcon icon={faTwitter} /></a>
                        <a href="/" aria-label="LinkedIn"><FontAwesomeIcon icon={faLinkedin} /></a>
                        <a href="/" aria-label="Instagram"><FontAwesomeIcon icon={faInstagram} /></a>
                    </div>
                </div>
                <div className="footer-newsletter">
                    <h3>Subscribe to our Newsletter</h3>
                    <form className="newsletter-form">
                        <input type="email" placeholder="Enter your email" required />
                        <button type="submit">Subscribe</button>
                    </form>
                </div>
            </div>
            <div className="footer-bottom">
                <p>&copy; 2024 Your Company. All rights reserved.</p>
            </div>
        </footer>
    );
};

export default Footer;